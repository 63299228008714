import React from "react"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Grid from "@mui/material/Grid"
import BookCard from "../../components/book-card/book-card.component"
import { graphql, useStaticQuery } from "gatsby"

const AllBooks = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      author: file(relativePath: { eq: "author-placeholder.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2880) {
            base64
            aspectRatio
            src
            srcSet
            sizes
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      book4: file(relativePath: { eq: "book4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2080) {
            base64
            aspectRatio
            src
            srcSet
            sizes
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allWpBook {
        edges {
          node {
            slug
            uri
            title
            featuredImage {
              node {
                altText
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                  }
                }
              }
            }
            booksFields {
              publicationDate
              heroSubtitleExcerpt
              authorRepeater {
                author {
                  ... on WpAuthor {
                    title
                    uri
                    authorFields {
                      heroTagName
                    }
                    featuredImage {
                      node {
                        altText
                        sourceUrl
                        title
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const books = staticQuery.allWpBook.edges

  books.sort(function (a, b) {
    let dateA = new Date(a.node.booksFields.publicationDate),
      dateB = new Date(b.node.booksFields.publicationDate)
    return dateB - dateA
  })

  if (!books) return null

  return (
    <>
      <div>
        <SectionWrapper contained>
          <Grid container spacing={2}>
            {books.map(book => (
              <Grid item xs={6} sm={4} md={3}>
                <BookCard
                  bookCover={
                    book.node.featuredImage && book.node.featuredImage.node
                  }
                  authorImage={
                    book.node.booksFields.authorRepeater &&
                    book.node.booksFields.authorRepeater[0]?.author
                      ?.featuredImage &&
                    book.node.booksFields.authorRepeater[0]?.author
                      ?.featuredImage?.node
                  }
                  authorName={
                    book.node.booksFields?.authorRepeater &&
                    book.node.booksFields?.authorRepeater[0]?.author?.title
                  }
                  role={
                    book.node.booksFields?.authorRepeater &&
                    book.node.booksFields?.authorRepeater[0]?.author
                      ?.authorFields?.heroTagName
                  }
                  link={book.node.uri}
                />
              </Grid>
            ))}
          </Grid>
          {/*<S.SeeMoreBtn>See More</S.SeeMoreBtn>*/}
        </SectionWrapper>

        {/*<FeaturedSuccessSlider*/}
        {/*  slides={[*/}
        {/*    {*/}
        {/*      quote: "Featured Success Story",*/}
        {/*      author: {*/}
        {/*        name: "Joe Theismann",*/}
        {/*        description:*/}
        {/*          "Joe Theismann is a household name, having built his reputation playing quarterback. A collegiate All-American in football and academics, he graduated from the University of Notre Dame in 1971 before embarking on a fifteen-year professional ...",*/}
        {/*        img: staticQuery.author,*/}
        {/*      },*/}
        {/*    }*/}
        {/*  ]}*/}
        {/*/>*/}
      </div>
    </>
  )
}
export default AllBooks
