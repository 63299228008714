import React, { useRef } from "react"
import Layout from "../../components/layout"
import HeroBanner from "../../layouts/HeroBanner"
import * as S from "../../styles/templates/book.styles"
import QuoteSection from "../../components/quote-section/quote-section.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import Box from "@mui/material/Box"
import { graphql } from "gatsby"
import BookContentSection from "../../components/book-content-section/book-content-section.component"

export const query = graphql`
  query BookQuery($id: String!) {
    amazon: file(relativePath: { eq: "amazon.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    barnes: file(relativePath: { eq: "barnes.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }

    wpBook(id: { eq: $id }) {
      slug
      uri
      title
      featuredImage {
        node {
          altText
          sourceUrl
          title
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      booksFields {
        allSuccessStoriesButton {
          title
          url
        }
        websiteInfo {
          text
          link {
            title
            url
          }
          image {
            altText
            sourceUrl
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
          }
        }
        amazonLink
        podcastLink
        bookshopLink
        podcastImage {
          altText
          sourceUrl
          title
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
        authorRepeater {
          author {
            ... on WpAuthor {
              id
              title
              uri
              slug
              featuredImage {
                node {
                  altText
                  sourceUrl
                  title
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                }
              }
            }
          }
        }
        heroSubtitleExcerpt
        barnesNobleLink
        inTheMediaContent {
          link
          linkText
        }
        quoteAuthor
        quoteText
        backgroundPosition
      }
      content
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
    }
  }
`

const Book = ({ data }) => {
  // const { seo, title, content, featuredImage, booksFields } = data

  const {
    booksFields: {
      allSuccessStoriesButton,
      websiteInfo,
      amazonLink,
      podcastLink,
      podcastImage,
      authorRepeater,
      heroSubtitleExcerpt,
      barnesNobleLink,
      bookshopLink,
      inTheMediaContent,
      quoteAuthor,
      quoteText,
      backgroundPosition,
    },
    title,
    content,
    featuredImage,
    seo,
    slug,
  } = data.wpBook
  const canonical = `${process.env.GATSBY_WEBSITE_URL}/book/${slug}/`
  let slider = useRef(null)
  const settingsSlider = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [],
  }
  const next = () => {
    slider.slickNext()
  }

  const previous = () => {
    slider.slickPrev()
  }

  return (
    <Layout seo={{ ...seo, canonical }}>
      <HeroBanner
        image={featuredImage && featuredImage.node}
        title={title}
        description={heroSubtitleExcerpt}
        authors={authorRepeater}
        // authorImage={
        //   authorRepeater &&
        //   authorRepeater[0].author.featuredImage &&
        //   authorRepeater[0].author.featuredImage.node
        // }
        // authorName={authorRepeater && authorRepeater[0].author.title}
        // authorUrl={booksFields.authorRepeater[0].author.uri}
        className="blur"
        backgroundPosition={backgroundPosition}
      />

      <BookContentSection
        content={content}
        amazonLink={amazonLink}
        barnesNobleLink={barnesNobleLink}
        websiteInfo={websiteInfo}
        featuredImage={featuredImage}
        podcastImage={podcastImage}
        podcastLink={podcastLink}
        bookshopLink={bookshopLink}
      >
        {inTheMediaContent && inTheMediaContent.length > 0 && (
          <S.SliderContainer>
            <S.SmallTitle style={{ display: "inline" }}>
              In the media
            </S.SmallTitle>
            {/*<div className="arrowsContainer">*/}
            {/*  <ArrowBackIcon onClick={previous} />*/}
            {/*  <ArrowForwardIcon onClick={next} />*/}
            {/*</div>*/}
            <S.CustomSlider {...settingsSlider} ref={c => (slider = c)}>
              <div>
                <ul>
                  {inTheMediaContent.map(link => (
                    <li>
                      <a target="_blank" href={link.link}>
                        {link.linkText}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </S.CustomSlider>
          </S.SliderContainer>
        )}
      </BookContentSection>

      {quoteText && <QuoteSection text={quoteText} author={quoteAuthor} />}

      <Box
        style={{
          textAlign: "center",
          backgroundColor: "#f2f0ec",
          paddingBottom: "6em",
        }}
      >
        <CustomButton href="/radius-book-group-titles/" className="darkBorder">
          Return to Books
        </CustomButton>
      </Box>
    </Layout>
  )
}

export default Book
